import componentInitializer from "@/frontend/utils/componentInitializer"
import axios from "axios"

const Http = axios.create({
  baseURL: "/",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
})

componentInitializer(() => {
  const el = document.querySelector('meta[name="csrf-token"]')
  Http.defaults.headers.common["X-CSRF-Token"] = el && el.getAttribute("content")
})

export default Http
